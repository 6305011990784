<template>
    <div class="service-info" style="overflow:hidden;background-color: #f0f0f0;min-width: 1500px;">
        <div class="service-body">
            <div class="service-left">
<!--                <div style="display: flex;">-->
<!--                    <div class="head-card" style="margin-right: 10px">-->
<!--                        <div style="display: flex; justify-content: space-between">-->
<!--                            <div class="work-title">-->
<!--                                <div class="work-icon"></div>-->
<!--                                <div>服务订单信息</div>-->
<!--                            </div>-->
<!--                            <div style="margin-right: 10px; color: #999999; margin-top: 10px">-->
<!--                                订单号：20220317_164946_206070-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div style="padding:16px 20px">-->
<!--                            <div style="color: #031F88; font-size: 18px; margin-bottom: 10px">公司企业品牌识别logo设计标志图标商标光荣文案卡通字体门头vi设计</div>-->
<!--                            <div style="margin-bottom: 10px">网页设计： 主图 / banner设计</div>-->
<!--                            <div style="display: flex; justify-content: space-between">-->
<!--                                <div>服务费用： <span style="color: #EE3F4D; font-size: 22px">￥ 140</span></div>-->
<!--                                <div>-->
<!--                                    <el-input-number v-model="num" :min="1" :max="100" size="small"></el-input-number>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="head-card">-->
<!--                        <div style="display: flex; justify-content: space-between">-->
<!--                            <div class="work-title">-->
<!--                                <div class="work-icon"></div>-->
<!--                                <div>专家订单信息</div>-->
<!--                            </div>-->
<!--                            <div style="margin-right: 10px; color: #999999; margin-top: 10px">-->
<!--                                订单号：20220317_164946_206070-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div style="padding:16px 20px">-->
<!--                            <div style="color: #031F88; font-size: 18px; margin-bottom: 10px">咨询标题：请求专家对该服务成果进行专业审查</div>-->
<!--                            <div style="margin-bottom: 10px">咨询内容：匠二品牌，国内专业品牌设计机构，坚持创新个性设计，物美-->
<!--                                价廉的品质获得诸多客户认集齐不同性格设计手赋予企业鲜活力创造力专-->
<!--                                注平面设计。</div>-->
<!--                            <div style="display: flex; justify-content: space-between">-->
<!--                                <div>服务费用： <span style="color: #EE3F4D; font-size: 22px">￥ 140</span></div>-->
<!--                                <div>-->
<!--                                    <span class="hand op" style="color: #2970FF">查看专家订单 > </span>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->

                <div class="body-card">
                  <div style="display: flex; justify-content: space-between">
                    <div class="work-title">
                      <div class="work-icon"></div>
                      <div>任务委托详情</div>
                    </div>
                    <div v-if="user.uuid === taskInfo.fUser.uuid && taskInfo.status !== 1" @click="editTask" class="hand op" style="margin: 20px 20px 0 0;border-radius: 5px; width: 50px; padding: 4px 0; text-align: center; color: #FFFFFF; background-color: #FF9900;">编辑</div>
                  </div>

                    <div style="padding:20px 40px">
                        <div class="service-tab">
                            <div class="title">
                                任务委托
                            </div>
                            <div class="content">
                                {{taskInfo.title}}
                            </div>
                            <div class="title">
                                委托类型
                            </div>
                            <div class="content">
                                任务委托
                            </div>
                            <div class="title">
                                订单状态
                            </div>
                            <div v-if="taskInfo.status === 0" class="content" style="color: #7BC526">
                                进行中
                            </div>
                            <div v-if="taskInfo.status === 1" class="content" style="color: #7BC526">
                                已完成
                            </div>
                        </div>
                        <div class="service-tab">
                        <div class="title">
                            委托方
                        </div>
                        <div class="content" style="color: #57AAFF" v-if="taskInfo.fUser">
                            {{taskInfo.fUser.nickName}}
                        </div>
                        <div class="title">
                            承接方
                        </div>
                        <div class="content" style="color: #57AAFF" v-if="taskInfo.trustee">
                            {{taskInfo.trustee.nickName}}
                        </div>
                        <div class="title">
                            来自room
                        </div>
                        <div class="content" style="color: #031F88">
                            {{taskInfo.roomName}}
                        </div>
                    </div>
                        <div class="service-tab">
                            <div class="title">
                                委托详情
                            </div>
                            <div style="width: 90%">
                                {{taskInfo.detail}}
                            </div>
                        </div>
                        <div class="service-tab">
                            <div class="title">
                                成果要求
                            </div>
                            <div style="width: 56%; margin-right: 3%">
                                {{taskInfo.ask}}
                            </div>
                            <div class="title">
                                提交格式
                            </div>
                            <div class="content">
                                {{taskInfo.format}}
                            </div>
                        </div>
                        <div class="service-tab">
                            <div class="title">
                                创建时间
                            </div>
                            <div class="content">
                                {{taskInfo.creatTime}}
                            </div>
                            <div class="title">
                                截止时间
                            </div>
                            <div class="content">
                                {{taskInfo.finishTime}}
                            </div>
                            <div v-if="taskInfo.expert" style="display: flex">
                                <div class="title">
                                    审核专家
                                </div>
                                <div class="hand op" style="color: #031F88;margin-left: 6px" v-for="(item, index) in taskInfo.expert" :key="index">
                                    {{item.nickName}}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="body-card">
                    <div class="work-title">
                        <div class="work-icon"></div>
                        <div>服务成果</div>
                    </div>
                    <div style="padding:20px 40px">
                        <el-tabs type="border-card" @tab-click="selectTab" v-model="selectName">
                            <el-tab-pane label="填写成果" :name="'0'" v-if="user.uuid === taskInfo.trustee.uuid && taskInfo.status !== 1">
                                <Editor :tinymce_height="'600'" id="1" :innerDrawer="innerDrawer = true" :value="resultInfo.content" ref="child"></Editor>
                                <div style="display: flex; justify-content: space-between; margin: 20px 0 10px 0">
                                    <el-upload  action="" multiple :http-request="uploadFile" :show-file-list="false" >
                                        <div class="foot-btn hand op" style="border: 1px #FF9900 solid; color: #FF9900">+ 上传附件</div>
                                    </el-upload>
                                    <div @click="submitResult" class="foot-btn hand op" style="background-color: #FF9900; color: #FFFFFF">提交成果</div>
                                </div>
                            </el-tab-pane>
                            <template v-if="taskInfo.goodsContent.length > 0">
                                <el-tab-pane :name="user.uuid === taskInfo.trustee.uuid && taskInfo.status !== 1 ? String(index + 1): String(index)" :label="item.time" v-for="(item, index) in taskInfo.goodsContent" :key="index">
                                    <div style="padding:10px 40px">
                                        <div v-html="item.content"></div>
                                      <div style="margin: 30px 0 0 0" v-if="user.uuid === taskInfo.fUser.uuid && taskInfo.status !== 1">
                                        <el-input
                                            type="textarea"
                                            :rows="3"
                                            placeholder="请输入此次成果的意见"
                                            v-model="opinions">
                                        </el-input>
                                        <div style="display: flex; justify-content: end; margin-top: 10px">
                                          <div @click="submitOpinion(item.orderNo)" class="hand op" style="border-radius: 5px; width: 50px; padding: 4px 0; text-align: center; color: #FFFFFF; background-color: #409EFF;">提交</div>
                                        </div>
                                      </div>
                                      <div v-if="item.askList" style="margin-top: 20px">
                                        <div class="tabs-box" style="box-shadow: 0 2px 12px 0 rgba(0,0,0,.1); padding: 20px; margin: 10px 0" v-for="(opinion, indexs) in item.askList" :key="indexs">
                                          <div style="margin-bottom: 6px; display: flex; justify-content: space-between">
                                            <div style="display: flex">
                                              <div style="color: #2970FF; margin-right: 20px; font-weight: 600; margin-bottom: 4px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden">{{taskInfo.fUser.nickName}}</div>
                                              <div style="color: #999999; font-size: 14px">{{opinion.time}}</div>
                                            </div>
                                            <div v-if="user.uuid === taskInfo.fUser.uuid && taskInfo.status !== 1" @click="deleteTask(item.orderNo, opinion.askId)" class="hand op" style="border-radius: 5px; width: 50px; padding: 4px 0; text-align: center; color: #FFFFFF; background-color: #FF9900;">删除</div>
                                          </div>
                                          <div>
                                            <div v-html="opinion.content"></div>
                                          </div>
                                        </div>
                                      </div>

                                    </div>
                                </el-tab-pane>
                            </template>
                            <div v-if="taskInfo.goodsContent.length === 0 && user.uuid !== taskInfo.trustee.uuid">
                                <NullBox style="text-align: center" data="成果信息"></NullBox>
                            </div>
                        </el-tabs>
                        <div style="display: flex; justify-content: end; margin-top: 20px">
                            <div @click="acceptanceResult" v-if="taskInfo.status === 0 && taskInfo.goodsContent.length > 0 && user.uuid === taskInfo.fUser.uuid" class="foot-btn hand op" style=" background-color: #FF9900; color: #FFFFFF">成果验收</div>
                            <div v-if="taskInfo.status === 1" class="foot-btn" style=" background-color: #EBEBEB;">已完成</div>
                        </div>

                        <div style="margin-top: 20px" v-if="fileList.length > 0">
                            <div style="font-weight: 600; margin-bottom: 10px">附件</div>
                            <div style="margin-top: 20px">
                                <div class="files">
                                    <div class="file-box" v-for="(item, index) in fileList" :key="index">
                                        <img src="https://oss.baigongbao.com/img/file.png" style="width: 24px; height: 20px; margin-right: 12px; vertical-align: middle">
                                        <span style="font-size: 18px; vertical-align: middle">{{item.name}}</span>
                                        <div style="float: right">
                                        <span class="hand op" style="background-color: #FFFFFF; margin-right: 10px; text-align: center; color: #FF9800; padding: 2px 10px; border-radius: 20px"
                                              @click="downloadFiles(item.url, item.name)"><i class="el-icon-download"></i> 下载</span>
                                            <span v-if="user.uuid === taskInfo.trustee.uuid" class="hand op" style="background-color: #FFFFFF; text-align: center; color: #FF9800; padding: 2px 10px; border-radius: 20px"
                                                  @click="removeFile(index)"><i class="el-icon-delete"></i> 删除</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="taskInfo.goodsContent.length > 0">
                        <div class="work-title">
                            <div class="work-icon"></div>
                            <div>专家审查意见</div>
                        </div>
                        <div style="padding:20px 40px; overflow: hidden">
                            <div v-if="taskInfo.expert.find(item => { return  item.uuid === user.uuid })" style="overflow: hidden">
                                <Editor :tinymce_height="'550'" :innerDrawer="innerDrawer = true" :value="content" ref="childs"></Editor>
                                <div @click="submitIdea" class="foot-btn hand op" style="float: right; margin-top: 20px; color: #FFFFFF; background-color: #2970FF">上传意见</div>
                            </div>
                            <div v-if="selectData">
                                <div class="tabs-box" style="box-shadow: 0 2px 12px 0 rgba(0,0,0,.1); padding: 20px; margin: 20px 0" v-for="(expert, index) in selectData.expertOpinion" :key="index">
                                    <div style="display: flex; margin-bottom: 6px">
                                        <div>
                                            <div style="color: #2970FF; font-weight: 600; margin-bottom: 4px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden">{{expert.nickName}}</div>
                                            <div style="color: #999999; font-size: 14px">{{expert.time}}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div v-html="expert.content"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="service-right">
                <div class="body-card" style="margin: 0; min-height: 200px">
                    <div class="work-title">
                        <div class="work-icon"></div>
                        <div>订单动态</div>
                    </div>
                    <div style="padding:16px 20px">
                        <el-timeline>
                            <el-timeline-item v-for="(activity, index) in taskInfo.log" :key="index" color="#2970FF" size="large">
                                <span style="color: #2970FF">{{activity.content}}</span>
                                <div style="margin-top: 10px">
                                    <div style="display: flex; margin-bottom: 10px">
                                        <div class="service-icon"></div><span>{{activity.time}}</span>
                                    </div>
                                </div>
                            </el-timeline-item>
                        </el-timeline>
                    </div>
                </div>
            </div>
        </div>
      <!--编辑弹窗-->
      <el-dialog :visible.sync="showTask" width="52%" :close-on-click-modal="false" :show-close="false" class="event-form">
        <div class="top">
                <span class="contentType">
                    <i class="el-icon-s-home" style="margin-right: 5px;"></i>
                    <span>{{taskInfo.roomName}}</span>
                </span>
          <span>编辑任务委托</span>
          <img src="../expert/imgs/cancel.png" style="width: 32px; height: 32px;position: absolute;right: 30px;top: 14px;cursor: pointer;" @click="closeEvent">
        </div>
        <div>
          <AddDelegate @susTask="susTask" :taskInfo="taskInfo"></AddDelegate>
        </div>
      </el-dialog>
    </div>
</template>

<script>
    import Editor from "@/components/common/Editor.vue";
    import NullBox from "./components/task/NullBox";
    import ServiceForm from "@/pages/room/components/task/ServiceForm.vue";
    import ProductForm from "@/pages/room/components/task/ProductForm.vue";
    import AddDelegate from "@/pages/room/components/task/AddDelegate.vue";
    export default {
        name: "serviceInfo",
        components: {AddDelegate, ProductForm, ServiceForm, NullBox, Editor},
        data() {
            return {
              num: 1,
              content: '',
              activities: [
                {
                  content: '您发布了任务委托订单',
                  type: 1
                },
                {
                  content: '承接方接受任务委托订单',
                  type: 2
                },
                {
                  content: '承接方已提交初稿方案',
                  type: 3
                },
                {
                  content: '承接方已上传修改方案',
                  type: 4
                },
                {
                  content: '验收成功，任务结束',
                  type: 5
                }
              ],

              taskInfo: {
                trustee: {},
                fUser: {},
                goodsContent: []
              },
              resultInfo: {
                content: '',
                files: []
              },
              fileList: [],

              selectData: {},
              selectName: '',

              // 委托方意见
              showOpinion: false,
              selectResult: {},
              opinions: '',

              // 编辑任务
              showTask: false,

            }
        },

        computed: {
            user() {
                return this.$store.state.user
            },
            is_login(){
                return this.$store.state.is_login
            },
        },

        mounted() {
            this.getTaskInfo()
        },

        methods: {
            // 关闭事件弹窗
            closeEvent() {
              this.showTask = false
            },

            // 编辑完成
            susTask() {
              this.showTask = false;
              this.getTaskInfo()
            },

            // 编辑任务
            editTask() {
              this.showTask = true
            },

            // 提交成果意见
            submitOpinion(orderNo) {
                let params = {};
                params.taskId = this.taskInfo.id;
                params.ask = this.opinions;
                params.orderNo = orderNo;
                this.newApi.addTaskResultAsk(params).then(res => {
                  if (res.isSuccess === 1) {
                    this.utils.sus(res.data);
                    this.opinions = '';
                    this.getTaskInfo();
                  }
                })
            },

            // 删除成果意见
            deleteTask(orderNo, askId) {
              let params = {};
              params.taskId = this.taskInfo.id;
              params.askId = askId;
              params.orderNo = orderNo;
              this.newApi.delTaskResultAsk(params).then(res => {
                if (res.isSuccess === 1) {
                  this.utils.sus(res.data);
                  this.getTaskInfo();
                }
              })
            },


            // 成果意见
            resultOpinion(item) {
              this.showOpinion = true;
              this.selectResult = item
            },

            // 验收成果
            acceptanceResult() {
                this.newApi.confirmTask({taskId: this.$route.params.id}).then(res => {
                    if (res.isSuccess === 1) {
                        this.utils.sus(res.data)
                        this.getTaskInfo();
                    }
                })
            },

            // 上传意见
            submitIdea() {
                let params = {};
                params.taskId = this.$route.params.id;
                params.content = this.$refs.childs.get_content();
                params.orderNo = this.selectData.orderNo;
                this.newApi.addTaskExpertResult(params).then(res => {
                    if (res.isSuccess === 1) {
                        this.utils.sus(res.data)
                        this.getTaskInfo();
                    }
                })
            },

            // 选择标签
            selectTab(e) {
                if (e.name === 0) {
                    this.selectData = {}
                } else {
                    if (this.user.uuid === this.taskInfo.trustee.uuid) {
                        this.selectData = this.taskInfo.goodsContent[e.name - 1]
                    } else {
                        this.selectData = this.taskInfo.goodsContent[e.name]
                    }
                }
            },

            // 提交成果
            submitResult() {
                var that = this;
                var param = {};
                param.taskId = this.$route.params.id;
                param.content = this.$refs.child.get_content();
                if (!param.content) {
                    this.utils.err('请填写成果内容');
                    return;
                }
                //处理附件统一上传
                if (that.fileList.length > 0){
                    let filesPromise = [];
                    for (let i = 0; i < that.fileList.length; i++) {
                        filesPromise.push(new Promise(function (resolve) {
                            if(that.fileList[i].uid) {
                                that.utils.upload(that.fileList[i],function(url){
	                                if (!url){
		                                return false;
	                                }
                                    let obj = {};
                                    obj.name = url.name;
                                    obj.url = url.url;
                                    obj.hash = url.hash;
                                    resolve(obj);
                                })
                            } else {
                                resolve(that.fileList[i])
                            }

                        }))
                    }
                    Promise.all(filesPromise).then(res=>{
                        param.files = JSON.stringify(res);
                        that.newApi.addTaskResult(param).then(res => {
                            if (res.isSuccess === 1) {
                                that.utils.sus(res.data)
                                that.selectName = '1'
                                that.getTaskInfo();
                            }
                        })
                    })
                } else {
                    param.files = [];
                    that.newApi.addTaskResult(param).then(res => {
                        if (res.isSuccess === 1) {
                            that.utils.sus(res.data)
                            that.selectName = '1'
                            that.getTaskInfo();
                        }
                    })
                }
            },

            //附件上传
            uploadFile:function(params){
                var that = this;
                var file = params.file;
                that.fileList.push(file);
            },

            // 下载附件
            downloadFiles(url, name) {
                this.utils.download(url, name)
            },

            // 移除附件
            removeFile(index) {
                var that = this;
                that.fileList.splice(index, 1);
                that.utils.sus('删除成功');
            },

            // 获取任务详情
            getTaskInfo() {
                if (this.$refs.child) {
                    this.$refs.child.set_content('')
                }
                if (this.$refs.childs) {
                    this.$refs.childs.set_content('')
                }
                this.resultInfo = {content: '', files: []};
                this.fileList = [];
                this.content = '';
                this.newApi.getTaskInfo({taskId: this.$route.params.id}).then(res => {
                    this.taskInfo = res.data;
                    if (this.taskInfo.goodsContent.length > 0) {
                        this.selectData = this.taskInfo.goodsContent[0]
                    }
                    this.fileList = res.data.enclosures
                })
            },
        }
    }
</script>

<style scoped>
    .service-body {
        width: 1500px;
        display: flex;
        margin: 110px auto;
    }

    .service-left {
        width: 1136px;
        margin-right: 10px;
    }

    .service-right {
        width: 354px;
        overflow: hidden;
    }

    .head-card {
        width: calc(100% - 10px / 2);
        height: 200px;
        background-color: #FFFFFF;
    }

    .body-card {
        margin-bottom: 10px;
        width: 100%;
        background-color: #FFFFFF;
    }

    .service-body .work-title {
        display: flex;
        line-height: 20px;
        padding-top: 16px;
        font-size: 18px;
        font-weight: 600;
        color: #031F88;
    }

    .service-body .work-title .work-icon {
        width: 6px;
        height: 20px;
        background-color: #031F88;
        margin-right: 14px;
    }

    .service-tab {
        display: flex;
        padding: 20px 0;
        border-bottom: 1px #E1E1E1 solid;
    }

    .service-body>>>img {
        max-width: 100%;
        max-height: 100%;
    }

    .service-body .files{
        overflow: hidden;
        overflow-y: scroll;
        max-height: 300px;
        line-height: 26px;
        font-size: 15px;
    }

    .service-body .files::-webkit-scrollbar {
        display: none;
    }

    .service-body .file-box {
        height: 32px;
        padding: 8px 10px;
        margin-bottom: 4px;
        background-color: #F1F1F1;
    }

    .service-body .title {
        width: 100px;
        color: #888888;
    }

    .service-body .content {
        width: 260px;
    }

    .service-body .foot-btn {
        width: 108px;
        height: 24px;
        font-size: 16px;
        border-radius: 5px;
        text-align: center;
        padding: 6px;
    }

    .service-body .service-icon {
        width: 6px;
        height: 6px;
        margin-top: 7px;
        margin-right: 6px;
        background-color: #2970FF;
        border-radius: 50%;
    }

    .service-body .btn {
        margin-left: 4px;
        padding: 1px 10px;
        font-size: 14px;
        border-radius: 5px;
        color: #FFFFFF;
    }

    .event-form .top {
      text-align: center;
      position: relative;
      font-size: 20px;
      font-weight: 600;
      color: #444444;
      border: 1px solid #CECFD0;
      height: 60px;
      box-sizing: border-box;
      line-height: 60px;
    }

    .event-form .contentType {
      font-size: 18px;
      color: #031F88;
      position: absolute;
      left: 20px;
      font-weight: 600;
      margin-right: 10px;
    }

    /deep/ .event-form .el-dialog__header {
      padding: 0;
    }

    /deep/ .event-form .el-dialog__body {
      padding: 0;
    }
</style>
